* {
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content {
  flex: 1 0 auto;
}
.footer {
  flex: 0 0 auto;
}

.invalid-feedback {
  margin: 10px 0;
  color: tomato;
}
